export const helper = {
  isPc() {
    const userAgentInfo = global?.navigator?.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },
  checkEnv() {
    const isPc = this.isPc()
    const isBrower =  !(typeof window === 'undefined')
    const isIopayMobile = window?.navigator?.userAgent && (window?.navigator?.userAgent.includes('IoPayAndroid') || window?.navigator?.userAgent.includes('IoPayiOs'))
    console.log('isBrower', isBrower, 'isIopayMobile' , isIopayMobile, 'isPc', isPc, isBrower && !isIopayMobile)
    let flag = true
    if (!isPc) {
      if (isBrower && !isIopayMobile) {
        this.openDeepLink()
        flag = false
      }
      flag = true
    }
    return flag;
  },
  openDeepLink() {
    const a = document.createElement("a");
    const tagId = "startIoPay";
    a.setAttribute("href", `iopay://io.iotex.iopay/open?action=web&url=${window.location.href}`);
    a.setAttribute("id", tagId);
    if (document.getElementById(tagId)) {
      // @ts-ignore
      document.body.removeChild(document.getElementById(tagId));
    }
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      window.location.href = 'https://iopay.me/'
    }, 4000)
  }
}