import React, { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { Button, CardBody, Image, Text } from '@mimoprotocol/uikit'
import { Wrapper, WrapperTitle, ImagesBox, CommingTxt } from './styleds'

export default function CreditCard() {
  const theme = useContext(ThemeContext)
  console.log(theme)
  return (
    <Wrapper>
      <WrapperTitle style={{background: theme.colors.backgroundAlt}}>
        <div>
          <Text fontSize="1.5rem">
            Buy IOTX with Credit Card
          </Text>
          <Text>
            Easy, Fast, Secure
          </Text>
        </div>
        <ImagesBox>
          <img src="/images/visa.png" alt=''  />
          <img src="/images/mastercard.png" alt=''  />
        </ImagesBox>
      </WrapperTitle>

      {/* <CommingTxt>Comming soon...</CommingTxt> */}

       <iframe id='iframe-widget' title="xxxx" src='https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=100&from=usd&horizontal=false&lang=en-US&link_id=f7e0dedacc4024&locales=true&logo=true&primaryColor=00C26F&to=iotx&toTheMoon=true' style={{height: '356px', width: '100%', border: 'none'}} />

    </Wrapper>
  )
}
