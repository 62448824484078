import { ChainId, JSBI, Percent, Token, WETH } from '@mimoprotocol/swap-sdk'

export const ROUTER_ADDRESS = '0x95cB18889B968AbABb9104f30aF5b310bD007Fd8'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// export const CAKE = new Token(
//   ChainId.MAINNET,
//   '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
//   18,
//   'CAKE',
//   'MimoSwap Token'
// )
export const WIOTX = new Token(
  ChainId.MAINNET,
  '0xa00744882684c3e4747faefd68d283ea44099d03',
  18,
  'WIOTX',
  'Wrapped IOTX'
)
// export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(
  ChainId.MAINNET,
  '0x84abcb2832be606341a50128aeb1db43aa017449',
  18,
  'BUSD-b',
  'Iotex Binance USD'
)
export const USDC = new Token(ChainId.MAINNET, '0x3b2bf2b523f54c4e454f08aa286d03115aff326c', 6, 'USDC', 'Iotex USDC')
export const CIOTX = new Token(
  ChainId.MAINNET,
  '0x99b2b0efb56e62e36960c20cd5ca8ec6abd5557a',
  18,
  'CIOTX',
  'Crosschain IOTX'
)
export const BTCB = new Token(
  ChainId.MAINNET,
  '0xc7b93720f73b037394ce00f954f849ed484a3dea',
  18,
  'WBTC',
  'Iotex Binance BTC'
)
export const USDT = new Token(
  ChainId.MAINNET,
  '0x6fbcdc1169b5130c59e72e51ed68a84841c98cd1',
  6,
  'USDT',
  'Iotex Tether USD'
)

export const ETH = new Token(
  ChainId.MAINNET,
  '0x0258866edaf84d6081df17660357ab20a07d0c80',
  18,
  'ETH',
  'Iotex Ethereum Token'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.IOTEXTESTNET]: [WETH[ChainId.IOTEXTESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], BUSD, BTCB, USDT, ETH, CIOTX],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], BUSD, USDT, USDC],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], BUSD, BTCB, USDT, USDC],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [CIOTX, WIOTX],
    [BUSD, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
