import { Text } from '@mimoprotocol/uikit'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 92%;
  @media screen and (min-width: 768px) {
    width: 658px;
  }
`

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3rem;
  box-shadow: 0px 3px 20px rgba(214, 214, 214, 0.502);
  border-radius: 10px;
  padding: 1rem;
  font-size: 1.5rem;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 1.8rem;
    justify-content: space-between;
    font-size: 1.8rem;
  }
`

export const CommingTxt = styled.div`
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 1.8rem;
  }
`

export const ImagesBox = styled.div`
  display: flex;
  img {
    width: 100px;
    height: 62px;
    margin-right: 10px;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
      width: 129px;
      margin-left: 20px;
      margin-top: 0;
    }
  }
  
`