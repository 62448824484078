import { DefaultTheme } from "styled-components";
import { light as lightCard } from "../components/Card/theme";
import { light as lightPancakeToggle } from "../components/PancakeToggle/theme";
import { light as lightRadio } from "../components/Radio/theme";
import { light as lightToggle } from "../components/Toggle/theme";
import { light as lightTooltip } from "../components/Tooltip/theme";
import { light as lightNav } from "../components/widgets/Menu/theme";
import { light as lightModal } from "../components/widgets/Modal/theme";
import { light as lightAlert } from "../components/Alert/theme";


import base from "./base";
import { lightColors } from "./colors";

const lightTheme: DefaultTheme = {
  ...base,
  alert: lightAlert,
  isDark: false,
  colors: lightColors,
  card: lightCard,
  toggle: lightToggle,
  nav: {
    background: lightNav.background,
    // hover: lightNav.background
  },
  modal: lightModal,
  pancakeToggle: lightPancakeToggle,
  radio: lightRadio,
  tooltip: lightTooltip,
};

export default lightTheme;
