import React from 'react'
import { Button, ButtonProps, useWalletModal} from '@mimoprotocol/uikit'
import { useTranslation } from 'react-i18next'
import useAuth from 'hooks/useAuth'
import { helper } from 'utils/helper'

const UnlockButton: React.FC<ButtonProps> = (props) => {
   const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <Button onClick={() => {
     if(helper.checkEnv()) onPresentConnectModal()
    }} {...props}>
      {t('Connect Wallet')}
    </Button>
  )
}

export default UnlockButton
