import { MenuEntry } from '@mimoprotocol/uikit'

const config: MenuEntry[] = [
  // {
  //   label: 'Home',
  //   icon: 'HomeIcon',
  //   href: '/',
  // },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
      {
        label: 'Liquidity  Migration',
        href: 'https://v1.mimo.exchange/pool',
      },
    ],
  },
  // {
  //   label: "Farm",
  //   icon: "FarmIcon",
  //   href: "/farms",
  // },
  {
    label: 'Analytics',
    icon: 'AnaLyticsIcon',
    href: 'https://v2.info.mimo.exchange/',
  },
  // {
  //   label: "Governance",
  //   icon: "GovernanceIcon",
  //   href: "",
  // },
  // {
  //   label: "Forum",
  //   icon: "ForumIcon",
  //   href: "/nft",
  // },
  {
    label: 'About',
    icon: 'AboutIcon',
    href: 'https://mimo.finance/',
  },
  {
    label: 'Docs',
    icon: 'DocsIcon',
    href: 'https://docs.mimo.finance/',
  },
  {
    label: 'Github',
    icon: 'GithubIcon',
    href: 'https://github.com/mimoprotocol',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/mimoprotocol',
  },
  {
    label: 'Blog',
    icon: 'AboutIcon',
    href: 'https://mimo.exchange/blog',
  },
  {
    label: 'Mimo V1',
    icon: 'TradeIcon',
    href: 'https://v1.mimo.exchange',
  },
]

export default config
